import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";

@Pipe({
  name: "dateFormat",
  standalone: true,
})
export class DateFormatPipe implements PipeTransform {
  userTimeZone = "";

  constructor(public datepipe: DatePipe) {
    // First get timezone value from Logged In User Details
    // this.userTimeZone = this.authService.getLoggedInUserDetails('timezone');
  }
  /**
   *
   * @param value date
   * @param type pipe type
   * @returns convert date format
   */
  
  transform(value: Date | string, type: any = ""): any {
    let formatType: any = null;
    switch (type) {
      case "withTime":
        formatType = "d MMM y h:mm a";
        break;
      case "onlyTime" :
        formatType = "shortTime";
        break;
      default:
        formatType = "d MMM y";
        break;
    }
    return this.datepipe.transform(value, formatType,"UTC");
  }
}
