<div class="row justify-content-center comman-table-wrapper">
  <div class="col-12">
    <div class="table-responsive">
      <table class="table table-striped mb-0" style="min-height: 100px"
        [ngClass]="{ loading: tableOptions().fetchingData,'table-height': tableOptions().fetchingData}">
        @if (showHeader()) {
        <thead >
          <tr>
            @for (column of displayedColumnArray(); track $index) {
            <th class="text-dark" [ngClass]="{ 'cursor-pointer': column.isSortable }">
              {{ column.label }}
              <span *ngIf="column.isSortable" class="position-relative ms-2">
                <i class="bi bi-arrow-up position-absolute" [ngClass]="{
                        disabled:
                          tableOptions()?.defaultSortField !== column.field ||
                          tableOptions()?.defaultSortOrder === 'desc'
                      }" (click)="sort(column.field)"></i>
                <i class="bi bi-arrow-down ms-2" [ngClass]="{
                        disabled:
                          tableOptions()?.defaultSortField !== column.field ||
                          tableOptions()?.defaultSortOrder === 'asc'
                      }" (click)="sort(column.field)"></i>
              </span>
            </th>
            }
          </tr>
        </thead>

        }
        <tbody class="table-border-bottom-0 text-dark">
          @if (data()?.length) {
          @for (item of data();let i=$index; track i) {
          <tr>
            @for (column of displayedColumnArray(); track $index) {
            @if (!column.isComponentSide) {
            <td>
              <ng-container [ngSwitch]="column?.type">
                <ng-container *ngSwitchCase="'dateWithTime'">
                  {{ item[column.field] | dateFormat : "withTime" }}
                </ng-container>
                <ng-container *ngSwitchCase="'string'">
                  {{
                  item[column.field]
                  ? (item[column.field] | titlecase)
                  : "-"
                  }}
                </ng-container>
                <ng-container *ngSwitchCase="'date'">
                  {{
                  item[column.field]
                  ? (item[column.field] | dateFormat)
                  : "-"
                  }}
                </ng-container>
                <ng-container *ngSwitchCase="'number'">
                  {{
                  item[column.field] ? (item[column.field] | number) : 0
                  }}
                </ng-container>
                <ng-container *ngSwitchCase="'percentage'">
                  {{
                  item[column.field] 
                  }}%
                </ng-container>
                <ng-container *ngSwitchCase="'price'">
                  {{
                  item[column.field] 
                  }}
                </ng-container>
                <ng-container *ngSwitchCase="'bold'"><strong>{{item[column.field]}}</strong>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  {{ item[column.field] ? item[column.field] : "-" }}
                </ng-container>
              </ng-container>
              <ng-container> </ng-container>
            </td>
            }
            @if (column.isComponentSide) {
            <ng-container *ngTemplateOutlet="isComponentSideAction();
                        context: { fieldName: column.field, item: item, index: i }
                      "></ng-container>
            }

            }
          </tr>
          }
          }@else {
          @if (!tableOptions().fetchingData) {
          <tr>
            <td class="text-center" [colSpan]="displayedColumnArray().length">
              <app-common-data-not-found [message]="tableOptions()?.notDataMessage"></app-common-data-not-found>
            </td>
          </tr>
          }
          }
        </tbody>
      </table>
    </div>
    @if (tableOptions()?.showPagination && data.length) {
    <div class=" p-0">
      <div class="card-footer p-0" style="height: 56px">
        <div class="row align-items-center mt-4">
          <div class="col-6">
            @if (tableOptions()?.showTotalRecords) {
            <div class="ps-3">
              <b>Total Records:</b> {{ tableOptions()?.totalRecord | number }}
            </div>
            }
          </div>
          <div class="col-6">
            <ngb-pagination class="d-flex justify-content-end me-3" [pageSize]="filterDetails?.pageSize"
              [collectionSize]="tableOptions()?.totalRecord" [page]="filterDetails?.page" size="sm"
              (pageChange)="changePagination($event)" [maxSize]="paginationMaxSize" [rotate]="true" [ellipses]="true">
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
    }
  </div>
</div>