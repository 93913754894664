<section class="datanot-found-wrapper">
    <div class="text-center">
        <div class="order-md-1 p-2 bd-highlight">
            <img src="/images/notfound.png" alt="No Data Found">
        </div>
        <div class="order-md-2 p-2 bd-highlight message-center">
            <h2 class="h5">
                {{ message() || 'No record available' }}
            </h2>
            @if(type() === 'cart'){
                <p><b>You haven' t added any stay or ride yet. <a routerLink="/home" class="text-danger">Click here</a> to continue booking.</b></p>
            }
        </div>
    </div>
</section>