import { Component, input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterLink } from "@angular/router";

@Component({
  selector: "app-common-data-not-found",
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: "./common-data-not-found.component.html",
  styleUrls: ["./common-data-not-found.component.scss"],
})
export class CommonDataNotFoundComponent {

  type = input<any>();
  message = input<any>();

}
