import {
  CurrencyPipe,
  DecimalPipe,
  KeyValuePipe,
  NgClass,
  NgFor,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
  NgTemplateOutlet,
  TitleCasePipe,
  UpperCasePipe,
} from "@angular/common";
import { Component, input, OnInit, output } from "@angular/core";
import { NgbPagination } from "@ng-bootstrap/ng-bootstrap";

import { PAGINATION_OPTIONS } from "../../../../config/app.constant";
import { DateFormatPipe } from "../../pipes/date-format.pipe";
import { PhoneNumberPipe } from "../../pipes/phone-number.pipe";
import { TitlecasePipeString } from "../../pipes/titlecase.pipe";
import { CommonDataNotFoundComponent } from "../common-data-not-found/common-data-not-found.component";
import { PriceChangesPipe } from "../../pipes/price-changes.pipe";

@Component({
  selector: "app-custom-data-table",
  templateUrl: "./custom-data-table.component.html",
  styleUrls: ["./custom-data-table.component.scss"],
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    NgFor,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    NgTemplateOutlet,
    NgbPagination,
    DecimalPipe,
    CurrencyPipe,
    DateFormatPipe,
    KeyValuePipe,
    PhoneNumberPipe,
    UpperCasePipe,
    TitlecasePipeString,
    TitleCasePipe,
    CommonDataNotFoundComponent,
    PriceChangesPipe
  ],
})
export class CustomDataTableComponent implements OnInit {
  
  data = input<any>();
  displayedColumnArray = input<any>();
  isComponentSideAction = input<any>();
  showHeader = input<any>();
  tableOptions = input<any>();

  changeTableData = output<object>();
  
  filterDetails: any = {};
  paginationMaxSize = PAGINATION_OPTIONS.INITIAL_PAGE_SIZE;
  pageSizeOptions = PAGINATION_OPTIONS.PAGE_SIZE_OPTIONS;

  ngOnInit() {
    this.resetFilterDetails();
  }

  /**
   * Reset Filter Details
   */
  resetFilterDetails() {
    this.filterDetails["page"] = this.tableOptions()?.page;
    this.filterDetails["pageSize"] = this.tableOptions()?.limit;
    this.filterDetails["sortBy"] = `${this.tableOptions()?.defaultSortField}`;
    this.filterDetails["sortOrder"] = `${this.tableOptions()?.defaultSortOrder}`;
  }

  /**
   * Change Pagination And emit Filter Details
   * @param page
   */
  changePagination(page: any) {
    this.filterDetails.page = page;
    this.changeTableData.emit(this.filterDetails);
    this.resetFilterDetails();
  }

  /**
   * Sort Table Data
   */
  sort(field: any) {
    this.tableOptions().defaultSortField = field;
    if (this.tableOptions().defaultSortField === field) {
      this.tableOptions().defaultSortOrder =
        this.tableOptions().defaultSortOrder === "desc" ? "asc" : "desc";
    }
    this.filterDetails["sortBy"] = `${this.tableOptions()?.defaultSortField}`;
    this.filterDetails["sortOrder"] = `${this.tableOptions()?.defaultSortOrder}`;
    // this.resetFilterDetails();
    this.filterDetails["filterType"] = "sort";
    this.changeTableData.emit(this.filterDetails);
  }
  // Custom function to check the value type and transform to title case
  // transformToTitleCase(value: any): string {
  //   if (typeof value === 'string') {
  //     return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  //   } else {
  //     return value.toString();
  //   }
  // }
}
